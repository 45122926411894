import React, { useEffect, useState } from 'react'
import { Button, Card, Form, ProgressBar, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as XLSX from 'xlsx';
import { uploadData } from '../actions/actions';

function FileAnalyzeWiz({type}) {

    let title = '';

    if(type ==='detailed-product-info'){
        title= 'Detailed Product Information'
    }else if(type ==='compatable-with'){
        title= 'Compatable With'
    }else if(type ==='product-description'){
        title= 'Product Description'
    }else if(type ==='installation-video'){
        title= 'Installation Video'
    }else if(type ==='ten-years-warranty'){
        title= '10 Years Warranty'
    }else if(type ==='break_in_procedure'){
        title= 'Break-In Procedure'
    }else if(type ==='return_policy'){
        title= 'Return Policy'
    }

    const [data, setData] = useState([]);  
    const [error, setError] = useState("");
    const [file, setFile] = useState("");  

    const [progress, setprogress] = useState(0);    
    const [analyze, setanalyze] = useState(false);    
    const [showUploaBtn, setShowUploaBtn] = useState(false);    
    const [loading, setloading] = useState(false);    


    const clearAll = ()=>{
        setloading(false);
        setShowUploaBtn(false)
        setanalyze(false)
        setprogress(0)
        setFile('')
        setError('')
        setData('')
    }

    const handleFileChange = (e) => {
        setError("");
        setanalyze(false); 
        if (e.target.files.length) {
            const inputFile = e.target.files[0];     
            
            setFile(inputFile);
        }
    }; 

    const handleSubmit = (e) => {
        e.preventDefault(); 
          
        var f = file;
          
        if(file != '') {
            setanalyze(true);
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, {type: 'binary'});
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
                setData(dataParse)
            };
            reader.readAsBinaryString(f)
        }else{
            toast.error("Please select the file again!");
            window.location.reload()
        }
    };

    useEffect(() => {
        if(analyze){
            let p = 0;
            
            let intval = setInterval(()=>{
                p = p+5;
                if(p>=100){
                    clearInterval(intval)
                }
                setprogress(p)
            },50)
           
        }
        
     
    }, [analyze])
    useEffect(() => {
      
        if(progress>=100){
            setShowUploaBtn(true)
            toast.success("Analysis completed!");
            setTimeout(()=>{
                toast.success(`${data.length} Row(s) found!`)
            },200)
        }else{
            setShowUploaBtn(false)
        }

    }, [progress])


    const terminateCurrentFile = ()=>{
        window.location.reload()
    }
    const uploadDataHandle = ()=>{
        setloading(true)
        console.log(data)
        uploadData({data: (data), type}).then(res=>{
            setloading(false)            
            toast.success(res.data.msg);
            clearAll();

        }).catch(err=>{
            setloading(false)
            toast.error(err.data.msg);
            clearAll();
        })
      }
    
    

  return (
    <>
    <Card >           
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
                Browse and select a xlsx file and click analyze then click upload xlxs button
            </Card.Text>
            
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Browse & select a .xlsx file</Form.Label>
                    <Form.Control required type="file" placeholder="choose file" onChange={handleFileChange} name="file" />
                    <Form.Text className="text-muted">
                    The xlsx file should have maximum 1000 rows at a time
                    </Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={analyze}>
                    Start Analyze {analyze && progress<100 && <span style={{marginLeft:'5px'}}><Spinner  size='sm'/></span>}
                </Button>
                {analyze && progress<100  &&<ProgressBar variant="success" now={progress} label={`${progress}%`} />}
            </Form> 

            {analyze && progress>=100 && data &&
                <> 
                    <Card.Title>{data.length - 1} Row(s) Found</Card.Title> 
                    <Card.Text>
                        Based on the analysis, we found {data.length} row(s) into the uploaded .xlsx file. Click Upload button to upload
                    </Card.Text>

                    { (data.length - 1)<=1000 ? (
                        <>
                        <Button variant="dark" onClick={uploadDataHandle} disabled={loading}>Upload {data.length} entries {loading && <span style={{marginLeft:'5px'}}><Spinner  size='sm'/></span>}</Button>
                        <Button style={{marginLeft:'10px'}} variant="light" onClick={terminateCurrentFile}>Cancel & Re-upload file</Button>
                        </>
                    ) : (<p style={{textAlign:'center',color:'#f00'}}>Selected Xlsx file having more than 1000 lines/rows. Will not be able to upload!</p>) }

                    
                    
                </>
            }
        </Card.Body>
    </Card> 
    </>
  )
}

export default FileAnalyzeWiz