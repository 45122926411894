import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { adminLogin, login } from '../../actions/auth';

function LoginForm({isAdmin=false}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userDetails, setuserDetails] = useState({ email: '', password:''});
    const [issubbmitting, setissubbmitting] = useState(false);
    const [errmsg, seterrmsg] = useState("");

    const handleSubmit =(e)=>{
        e.preventDefault();
        setissubbmitting(true);

        if(isAdmin){
          adminLogin(userDetails)
          .then((res) => {
          setissubbmitting(false);
          dispatch({
            type: "setUser",
            payload: res.data.user,
          });
          navigate("/admin/all-companies")
    
          })
          .catch((err) => {
            setissubbmitting(false);
            seterrmsg(err.data.msg);
          });

        }else{
    
        login(userDetails)
          .then((res) => {
          setissubbmitting(false);
          dispatch({
            type: "setUser",
            payload: res.data.user,
          });
          navigate("/")
    
          })
          .catch((err) => {
            setissubbmitting(false);
            seterrmsg(err.data.msg);
          });
        }
    
    
        
    }



  return (
    <>
        {errmsg && <div className="alert alert-danger" role="alert">
            {errmsg}
        </div>}        
        <form role="form" onSubmit={handleSubmit}>
            <div className="mb-3">
            <input
                type="email"
                className="form-control form-control-lg"
                placeholder="Email"
                aria-label="Email"
                required
                value={userDetails.email}
                onChange={(e)=> setuserDetails({...userDetails, email: e.target.value})}
            />
            </div>
            <div className="mb-3">
            <input
                type="password"
                className="form-control form-control-lg"
                placeholder="Password"
                aria-label="Password"
                required
                value={userDetails.password}
                onChange={(e)=> setuserDetails({...userDetails, password: e.target.value})}
            />
            </div>
            {/* <div className="form-check form-check-info text-start">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                
              />
              <label className="form-check-label" for="flexCheckDefault">
                I agree the
                <Link
                  to="https://app.termly.io/document/terms-of-use-for-website/8b4443d6-9456-4582-8422-db65894f0f80"
                  target="_blank"
                  className="text-dark font-weight-bolder"
                  > Terms and Conditions</Link
                > and  <Link
                  to="https://app.termly.io/document/privacy-policy/3b7dbd7a-776a-4865-a03d-292e5d31530f"
                  target="_blank"
                  className="text-dark font-weight-bolder"
                  > Privacy policy</Link
                >
              </label>
            </div> */}
            
            <div className="text-center">
            <button
                type="submit"
                className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
                disabled={issubbmitting}
            > Sign in&nbsp;
            {issubbmitting && ( <Spinner size='sm' />)}
            </button>
            </div>
        </form>
        
        
    </>
  )
}

export default LoginForm