import React, { useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'
import { getProductData } from '../actions/actions'
import { detailedTabHeaders } from '../data/Tabs'
import DataTable from './DataTable'

function DataFromDatabase({type}) {

   const [loading, setloading] = useState(false) 
   const [data, setdata] = useState([]) 


    const refreshData = ()=>{
        setloading(true)
        getProductData({type})
        .then(res=>{
            setloading(false)
            console.log(res)
            setdata(res.data.data)
        })
        .catch(err=>{
            setloading(false)
            console.log(err)
        })
    }
       



  return (
    <>
        <Card className="mt-3">
            <Card.Body>
                <button className="btn btn-success" onClick={refreshData}> Refresh Data {loading && <span style={{marginLeft:'5px'}}><Spinner  size='sm'/></span>}</button>

                {data &&  data.length > 0 ? <DataTable type={type} data={data} />: null}
            </Card.Body>
        </Card>
    </>
  )
}

export default DataFromDatabase