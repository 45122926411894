import API from "../shared/API";

const setupUserLogin = (res, resolve) => {
  localStorage.setItem("token", res.data.token);
  resolve(res);
};

export const adminLogin = (data) => {
  return new Promise((resolve, reject) => {
    API.post(`/auth/admin-login`, data)
      .then((res) => {
        setupUserLogin(res, resolve);
        //resolve(res);
      })
      .catch((error) => {
        reject(error);
        // console.log("err", error);
      });
  });
};

export const login = (data) => {
  return new Promise((resolve, reject) => {
    API.post(`/auth/login`, data)
      .then((res) => {
        setupUserLogin(res, resolve);
        //resolve(res);
      })
      .catch((error) => {
        reject(error);
        // console.log("err", error);
      });
  });
};

export const register = (data) => {
  return new Promise((resolve, reject) => {
    API.post(`/auth/register`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  localStorage.removeItem("token");
  return new Promise((resolve, reject) => {
    API.get(`/auth/logout`)
      .then((res) => {
        resolve(res);
        window.location.reload();
      })
      .catch((error) => {
        reject(error);
      });
  });
};
