import React from 'react'
import DataFromDatabase from './DataFromDatabase'
import FileAnalyzeWiz from './FileAnalyzeWiz'

function CompatableWith() {
  return (
    <> 
      <FileAnalyzeWiz type="compatable-with"/>
      {/* <DataFromDatabase type="compatable-with"/> */}
    </>
  )
}

export default CompatableWith