import React from 'react'
import DataFromDatabase from './DataFromDatabase'
import FileAnalyzeWiz from './FileAnalyzeWiz'

function InstallationVideo() {
  return (
    <> <FileAnalyzeWiz type="installation-video"/>
       {/* <DataFromDatabase type="installation-video"/> */}
    </>

  )
}

export default InstallationVideo