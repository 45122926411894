import React, { useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getProductBySlug } from '../actions/actions'
import { allTabs } from '../data/Tabs'

import DataTable from './DataTable'

import logo from "../assets/images/logo.jpg"

function SearchBySlug() {

    const [isLoading, setisLoading] = useState(false)
    const [slug, setslug] = useState('')
    const [type, settype] = useState('detailed-product-information')
    const [data, setdata] = useState('')

    const handleSubmit = (e)=>{  
        
        e.preventDefault()    
        setisLoading(true)

        getProductBySlug({slug,type}).then((res)=>{
            setisLoading(false)
            if(res.data.data.length > 0){
                setdata(res.data.data)
             }else{ 
                setdata("")
                toast.error("No data found!")
            };          
           
          
        }).catch((err)=>{
            setisLoading(false)
           toast.error("Something went wrong! Please try again later!");
        })
    }

    const clearSearch = () => {
        setslug('')
        setdata('')
    }

  return (
    <>
        <Row>
            <Col md="6" style={{paddingTop: '50px'}}>
             <img src={logo} width="100%" />
            </Col>
            <Col md="6">
                <Card className="mt-3 ">
                    <Card.Body>
                        <Card.Title>Search by product slug</Card.Title>
                        <Form onSubmit={handleSubmit} className="searchForm">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Enter the product slug and then press enter</Form.Label>
                                <Form.Control value={slug} required type="text" placeholder="Type slug.." onChange={(e)=>setslug(e.target.value) } name="slug" />                        
                            </Form.Group>
                            <Form.Label>Select the type</Form.Label>
                            <Form.Select required className='mb-3' onChange={(e)=> {settype(e.target.value); setdata(''); } }>
                                {allTabs.map((item)=>{
                                    return (
                                        <option value={item.key}>{item.title}</option>
                                    )
                                })}                                
                            </Form.Select>                            
                            <Button variant="success" type="submit" disabled={isLoading}>
                                Search {isLoading  && <span style={{marginLeft:'5px'}}><Spinner  size='sm'/></span>}
                            </Button> 
                            <Button variant="primary" onClick={clearSearch} style={{marginLeft:'15px'}}>
                                Clear
                            </Button>                   
                        </Form> 
                    </Card.Body>
                </Card>
            </Col>
            <Col md="12">
                {data &&<Card className="mt-3 ">
                    <Card.Body>
                        {!data && <Card.Text>No data found!</Card.Text>}
                        {data &&<DataTable type={type} data={data} />}
                    </Card.Body>
                </Card>}
            </Col>
        </Row>
    </>
  )
}

export default SearchBySlug