import { mainReducer } from "../reducers/mainReducer";

const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
  reducer: {
    main: mainReducer,
  },
});
export default store;
