import { Navigate } from "react-router-dom";
import Login from "../admin/pages/Login";
// import Company from "../components/Company";
import Home from "../components/Home";

// import Product from "../components/Product";
// import CompanyView from "../components/CompanyView";
// import ProductView from "../components/ProductView";

// import UserDashboard from "../components/Dashboard";
// import UserProfile from "../components/myProfile";
// import UserCompanies from "../components/myCompanies";

// Admin pages
// import Dashboard from "../admin/pages/Dashboard";

// import Register from "../admin/pages/Register";
// import AdminAllCompanies from "../admin/components/AdminAllCompanies";
// import ProductViewWithCompany from "../components/ProductViewWithCompany";
// import AdminAddCompanies from "../admin/components/AdminAddCompanies";
// import AdminAddproducts from "../admin/components/AdminAddproducts";
// import EditProduct from "../components/EditProduct";
// import AdminEditProduct from "../admin/components/AdminEditProduct";
// import EditCompany from "../components/EditCompany";
// import AdminEditCompany from "../admin/AdminEditCompany";
// import GoogleLoginSuccess from "../components/GoogleLoginSuccess";
// import RegisterNew from "../admin/pages/RegisterNew";

const Routes = (isLoggedIn, isLoggedInAdmin) => [
  //   {
  //     path: "/admin",
  //     element: isLoggedInAdmin ? (
  //       <Navigate to="/admin/dashboard" />
  //     ) : (
  //       <Navigate to="/admin/login" />
  //     ),
  //   },
  //   {
  //     path: "/admin/dashboard",
  //     element: isLoggedInAdmin ? <Dashboard /> : <Navigate to="/admin/login" />,
  //   },
  //   {
  //     path: "/admin/login",
  //     element: isLoggedInAdmin ? (
  //       <Navigate to="/admin/dashboard" />
  //     ) : (
  //       <Login isAdmin={true} />
  //     ),
  //   },
  //   {
  //     path: "/admin/all-companies",
  //     element: isLoggedInAdmin ? <AdminAllCompanies /> : <Login isAdmin={true} />,
  //   },
  //   {
  //     path: "/admin/add-companies",
  //     element: isLoggedInAdmin ? <AdminAddCompanies /> : <Login isAdmin={true} />,
  //   },
  //   {
  //     path: "/admin/add-products",
  //     element: isLoggedInAdmin ? <AdminAddproducts /> : <Login isAdmin={true} />,
  //   },
  //   {
  //     path: "/admin/edit-product/:id",
  //     element: <AdminEditProduct />,
  //   },
  //   {
  //     path: "/admin/edit-company/:id",
  //     element: <AdminEditCompany />,
  //   },
  //   {
  //     path: "/register",
  //     element: <RegisterNew />,
  //   },
  {
    path: "/login",
    element: isLoggedIn ? <Navigate to="/" /> : <Login />,
  },
  //   {
  //     path: "/google/login/success",
  //     element: <GoogleLoginSuccess />,
  //   },
  {
    path: "/",
    element: isLoggedIn ? <Home /> : <Login />,
  },
  //   {
  //     path: "/dashboard",
  //     element: isLoggedIn ? <UserDashboard /> : <Navigate to="/login" />,
  //   },
  //   {
  //     path: "/my-profile",
  //     element: isLoggedIn ? <UserProfile /> : <Navigate to="/login" />,
  //   },
  //   {
  //     path: "/my-companies",
  //     element: isLoggedIn ? <UserCompanies /> : <Navigate to="/login" />,
  //   },
  //   {
  //     path: "/company",
  //     element: isLoggedIn ? <Company /> : <Navigate to="/login" />,
  //   },
  //   {
  //     path: "/company/:id",
  //     element: <CompanyView />,
  //   },
  //   {
  //     path: "/product",
  //     element: isLoggedIn ? <Product /> : <Navigate to="/login" />,
  //   },
  //   {
  //     path: "/product/:id",
  //     element: <ProductView />,
  //   },
  //   {
  //     path: "/edit-product/:id",
  //     element: <EditProduct />,
  //   },
  //   {
  //     path: "/edit-company/:id",
  //     element: <EditCompany />,
  //   },
  //   {
  //     path: "/:companyslug/:productslug",
  //     element: <ProductViewWithCompany />,
  //   },
];

export default Routes;
