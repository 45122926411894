import BreakInProcedure from "../components/BreakInProcedure";
import CompatableWith from "../components/CompatableWith";
import DetailedProductInfo from "../components/DetailedProductInfo";
import InstallationVideo from "../components/InstallationVideo";
import ProductDescription from "../components/ProductDescription";
import ReturnPolicy from "../components/ReturnPolicy";
import TenYearsWarranty from "../components/TenYearsWarranty";

export const allTabs = [
  {
    title: "Performance Specs",
    key: "detailed-product-information",
    componant: <DetailedProductInfo />,
  },
  {
    title: "Compatable With",
    key: "compatable-with",
    componant: <CompatableWith />,
  },
  {
    title: "Product Description",
    key: "product-description",
    componant: <ProductDescription />,
  },
  {
    title: "Installation Video",
    key: "installation-video",
    componant: <InstallationVideo />,
  },
  {
    title: "10 Year Warranty",
    key: "10-years-warranty",
    componant: <TenYearsWarranty />,
  },
  {
    title: "Break-In Procedure",
    key: "break_in_procedure",
    componant: <BreakInProcedure />,
  },
  {
    title: "Return Policy",
    key: "return_policy",
    componant: <ReturnPolicy />,
  },
];

export const detailedTabHeaders = [
  // "SLUG",
  "Store Category",
  "UPC",
  "Brand",
  "Manufacturer Part Number",
  "Items Included",
  "Number in Pack",
  "Placement on Vehicle",
  "Rotor Style",
  "Pad Material",
  "Fitment Type",
  "Solid or Vented Rotor",
  "Warranty",
  "Mounting Hardware Included",
  "Bundle Description",
  "California Prop 65 Warning",
  "Brake Pad Compound",
  "Performance Part",
  "Material",
  "Type",
  "C:Bolt Hole Quantity",
  "C:Bolt Hole Quantity (Rear)",
  "Rotor Outside Diameter",
  "Rotor Weight",
  "Misc Holes",
  "Disc Mounting",
  "Surface Type",
  "Center Hole Diameter",
  "Item Height",
  "Nominal Thickness",
  "Stud/Lug Hole Diameter",
  "Discard Thickness",
  "Mounting Bolt Hole Circle Diameter",
  "Notes"
];
export const detailedTabKeys = [
  // "slug",
  "store_category",
  "upc",
  "brand",
  "manufacturer_part_number",
  "items_included",
  "number_in_pack",
  "placement_on_vehicle",
  "rotor_style",
  "pad_material",
  "fitment_type",
  "solid_or_vented_rotor",
  "warranty",
  "mounting_hardware_included",
  "bundle_description",
  "california_prop_65_warning",
  "brake_pad_compound",
  "performance_part",
  "material",
  "type",
  "c_bolt_hole_quantity",
  "c_bolt_hole_quantity_rear",
  "rotor_outside_diameter",
  "rotor_weight",
  "misc_holes",
  "disc_mounting",
  "surface_type",
  "center_hole_diameter",
  "item_height",
  "nominal_thickness",
  "stud_lug_hole_diameter",
  "discard_thickness",
  "mounting_bolt_hole_circle_diameter",
  "notes",
];

export const compatableTabHeaders = [
  // "Slug",
  "Position",
  "Type",
  "Year",
  "Make",
  "Model",
  "Trim",
  "Engine",
  "Notes",
];
export const compatableTabKeys = [
  // "slug",
  "position",
  "type",
  "year",
  "make",
  "model",
  "trim",
  "engine",
  "notes",
];

export const warrantyTabHeaders = [
  // "Slug",
  "10 Years warrenty",
];
export const warrantyTabKeys = [
  // "slug",
  "year_warranty",
];

export const installationTabHeaders = [
  //"Slug",
  "Video",
];
export const installationTabKeys = [
  //"slug",
  "video",
];

export const productDescriptionTabHeaders = [
  //"Slug",
  "Description",
];
export const productDescriptionTabKeys = [
  //"slug",
  "product_description",
];
export const breakInProcudureTabHeaders = [
  //"slug",
  "Break-In Procedure",
];
export const breakInProcudureTabKeys = [
  //"slug",
  "break_in_procedure",
];
export const returnPolicyTabHeaders = [
  //"slug",
  "Return Policy",
];
export const returnPolicyTabKeys = [
  //"slug",
  "return_policy",
];
