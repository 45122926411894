import { useRoutes } from "react-router-dom";
import { isLoggedIn, isLoggedInAdmin } from "./shared/Shared";

import Routes from "./routes/Routes";

function App() {
  const routing = useRoutes(Routes(isLoggedIn(), isLoggedInAdmin()));

  return <>{routing}</>;
}

export default App;
