import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'
import { ToastContainer } from 'react-toastify';

function Layout({children}) {
  return (
    <>
        <ToastContainer theme="colored"/>
        <div className="mainWraper">
            <Header/>
                <div className="pageContainer p-5">
                    <Container>
                        {children}
                    </Container>
                </div>
            {/* <Footer/> */}
        </div>
    </>
  )
}

export default Layout