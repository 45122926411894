import React from 'react'
import { Card } from 'react-bootstrap'
import { getProductData } from '../actions/actions'
import DataFromDatabase from './DataFromDatabase'
import FileAnalyzeWiz from './FileAnalyzeWiz'


function DetailedProductInfo() {


 

  return (
    <>
        <FileAnalyzeWiz type="detailed-product-info"/>
        {/* <DataFromDatabase type="detailed-product-info"/> */}
    </>
  )
}

export default DetailedProductInfo