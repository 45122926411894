import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'
import LoginForm from '../components/LoginForm'
import bgImg from "./../assets/images/reg-img.png"
import logo from "../../assets/images/logo.jpg"
import LoginWithGoogle from '../components/LoginWithGoogle'

function Login({isAdmin=false}) {
  return (
    <>
      <Layout>
        <main className="main-content mt-0">
          <section>
            <div className="page-header min-vh-100">
              <div className="container">
                <div className="row">
                  <div
                    className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
                  >
                    <div className="card card-plain">
                      <div className="card-header pb-0 text-start">
                        <img src={logo} alt="logo" style={{width: '100%', marginBottom:'3rem'}}/>
                        <h4 className="font-weight-bolder">Admin Log In</h4>
                        <p className="mb-0">Enter your email and password to sign in</p>
                      </div>
                      
                      <div className="card-body">
                        <LoginForm isAdmin={isAdmin}/>
                      </div>
                      {/* <div class="row px-xl-5 px-sm-4 px-3">
                        <div class="mt-2 position-relative text-center">
                          <p
                            class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                          >
                            or
                          </p>
                        </div>
                        <LoginWithGoogle/>
                      </div> */}
                      {/* <div className="card-footer text-center pt-0 px-lg-2 px-1">
                        <p className="mb-4 text-sm mx-auto">
                          Don't have an account?
                          <Link to="/register"  className="text-primary text-gradient font-weight-bold"> Sign up</Link>                         
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
                  >
                    <div
                      className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                      style={{backgroundImage:`url(${bgImg})`, backgroundSize:'cover', backgroundPosition:'center right'}}
                    >
                      <span className="mask bg-gradient-primary opacity-6"></span>
                      {/* <h4
                        className="mt-5 text-white font-weight-bolder position-relative"
                      >
                        "Attention is the new currency"
                      </h4>
                      <p className="text-white position-relative">
                        The more effortless the writing looks, the more effort the
                        writer actually put into the process.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>    
    </>
  )
}

export default Login