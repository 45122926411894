import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Insert authorization token on request call
    let token = process.env.REACT_APP_EXPLICIT_TOKEN;
    let local_store_token = localStorage.getItem("token");

    if (
      local_store_token &&
      typeof local_store_token != undefined &&
      local_store_token != ""
    ) {
      token = local_store_token;
    }

    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Updated response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status != 200) {
      //window.location.href = "/";
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default instance;
