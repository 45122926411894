import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { uploadData } from '../actions/actions'

import * as XLSX from 'xlsx';
import Layout from './Layout';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { allTabs } from '../data/Tabs';
import SearchBySlug from './SearchBySlug';



function Home() {

   // This state will store the parsed data
   const [data, setData] = useState([]);
     
   // It state will contain the error when
   // correct file extension is not used
   const [error, setError] = useState("");
    
   // It will store the file uploaded by the user
   const [file, setFile] = useState("");

   // This function will be called when
   // the file input changes
   const handleFileChange = (e) => {
       setError("");    
      
       if (e.target.files.length) {
           const inputFile = e.target.files[0];
           console.log(inputFile.name)
           console.log(inputFile.type)
           setFile(inputFile);
       }
   };
   const handleParse = () => {
        
    var f = file;
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        setData(dataParse)
        console.log(dataParse)
       console.log(JSON.stringify(dataParse))

       let arr = []
       dataParse[0].map(item=>{
            let col_name = item.toLowerCase().replaceAll(' ', '_').replaceAll('/', '_').replaceAll(':', '_')
            arr.push(col_name)
        })
        console.log(arr)


      
    };
    reader.readAsBinaryString(f)
   };

  useEffect(() => {

    
   
  }, [])


  const uploadDataHandle = ()=>{
    uploadData({data: (data)}).then(res=>{
        console.log(res)
    })
  }

  


  return (
    <>
    <Layout>         
        <Tabs
        defaultActiveKey={allTabs[0].key}
        id="fill-tab-example"
        className="mb-3"
        fill
        onSelect={(k) =>console.log(k)}
        >
            {allTabs.map(item=>{
                return (
                    <Tab key={item.key} eventKey={item.key} title={item.title} >
                        {item.componant}
                    </Tab>
                )
            })}          
           
        </Tabs> 

        <SearchBySlug/>
         

        {/* <div className='centerArea'>
            <div>
                <label htmlFor="csvInput" style={{ display: "block" }}>
                    Enter CSV File
                </label>
                <input
                    onChange={handleFileChange}
                    id="csvInput"
                    name="file"
                    type="File"
                />
                <div>
                    <button className='btn btn-success' onClick={handleParse}>Parse</button>
                </div>

                <div>
                    <button className='btn btn-success' onClick={uploadDataHandle}>Upload Data</button>
                </div>
                
                
            </div>
        </div>
        
        <ol>
        {data && data.length > 0 ? data[0].map(item=>{           
            return <li>{item.toLowerCase().replaceAll(' ', '_')}</li>
            }) : null}
        </ol> */}
        {data && data.length > 0 ? data[0].map(item=>{
            let col_name = item.toLowerCase().replaceAll(' ', '_').replaceAll('/', '_').replaceAll(':', '_')

            return `ALTER TABLE public.detailed_product_information ADD ${col_name} varchar NOT NULL;`
            //return <li>{item.toLowerCase().replaceAll(' ', '_')}</li>
            }) : null}        
    </Layout>
    </>
  )
}

export default Home