import { createReducer } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const getSavedUser = () => {
  let token = localStorage.getItem("token");
  if (token) {
    var decoded = jwt_decode(token);
    delete decoded.iat;
    return decoded;
  } else {
    return {};
  }
};

const initialState = {
  loader: false,
  user: getSavedUser(),
};

export const mainReducer = createReducer(initialState, {
  showLoader: (state, action) => {
    state.loader = action.payload;
  },
  checklogin: (state, action) => {
    let token = localStorage.getItem("token");
    if (token) {
      var decoded = jwt_decode(token);
      delete decoded.iat;
      state.user = decoded;
    } else {
      state.user = {};
    }
  },
  setUser: (state, action) => {
    state.user = action.payload;
  },
});
