import API from "../shared/API";

export const getProducts = (data) => {
  return new Promise((resolve, reject) => {
    API.get(`/shopify/getProducts`)
      .then((res) => {
        if (res.data.status == 400) {
          reject(res);
        } else {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadData = (data) => {
  return new Promise((resolve, reject) => {
    API.post(`/data/uploadData`, data, {
      onDownloadProgress: (d) => {
        console.log("download", d);
      },
      onUploadProgress: (d) => {
        console.log("upload", d);
      },
    })
      .then((res) => {
        if (res.data.status == 400) {
          reject(res);
        } else {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProductData = (data) => {
  return new Promise((resolve, reject) => {
    API.post(`/data/getProductData`, data, {
      onDownloadProgress: (d) => {
        console.log("download", d);
      },
      onUploadProgress: (d) => {
        console.log("upload", d);
      },
    })
      .then((res) => {
        if (res.data.status == 400) {
          reject(res);
        } else {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProductBySlug = (data) => {
  return new Promise((resolve, reject) => {
    API.post(`/shopify/product`, data)
      .then((res) => {
        if (res.data.status == 400) {
          reject(res);
        } else {
          resolve(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};