import React from 'react'

function Footer() {
  return (
    <footer>
        <p className="text-center">&copy; Copyright | APF PARTS | Ranit Majumder | 2023</p>
    </footer>
  )
}

export default Footer