import React from 'react'
import DataFromDatabase from './DataFromDatabase'
import FileAnalyzeWiz from './FileAnalyzeWiz'

function TenYearsWarranty() {
  return (
    <> <FileAnalyzeWiz type="ten-years-warranty" />
    {/* <DataFromDatabase type="ten-years-warranty"/> */}
    </>
  )
}

export default TenYearsWarranty