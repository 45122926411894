import React from 'react'
import Footer from './Footer'
import Header from './Header'

import "../assets/css/nucleo-icons.css";
import "../assets/css/nucleo-svg.css";
import "../assets/css/argon-dashboard.css"


function Layout({children}) {
  return (
    <>
        {/* <Header/> */}
        <div>
            {children}
        </div>
        {/* <Footer/> */}
    </>
  )
}

export default Layout