import React from 'react'
import DataFromDatabase from './DataFromDatabase'
import FileAnalyzeWiz from './FileAnalyzeWiz'

function BreakInProcedure() {
  return (
    <> <FileAnalyzeWiz type="break_in_procedure" />    
    </>
  )
}

export default BreakInProcedure