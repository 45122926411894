import React from 'react'
import { Container, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../actions/auth';

import logo from "../assets/images/logo.jpg"



function Header() {


    const {user} = useSelector((state) => state.main);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();   



    const handleLogout = (e)=>{
        e.preventDefault();
        logout();
    
        navigate("/");
        dispatch({
        type: "checklogin",
        });
    }


  return (
    <>
    <header>
        <Navbar bg="dark" variant="dark">
        <Container>
            <Navbar.Brand href="#home">
                <img className="mainLogoHeader" src={logo} alt="APF ADMIN"/>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                Signed in as: 
            </Navbar.Text>
                <NavDropdown title={`${user.name}`} >
                    
                    {/* <NavDropdown.Item href="#action4" onClick={handleLogout}>
                        Profile
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item href="#" onClick={handleLogout}>
                    Logout
                    </NavDropdown.Item>
                </NavDropdown>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    </header>
    </>
  )
}

export default Header