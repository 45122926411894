import jwt_decode from "jwt-decode";

export const isLoggedIn = () => {
  return localStorage.getItem("token") ? true : false;
};
export const isLoggedInAdmin = () => {
  const tok = localStorage.getItem("token");
  if (tok) {
    const d = jwt_decode(tok);
    return d.role == "admin" ? true : false;
  } else {
    return false;
  }
};
