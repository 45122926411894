import React from 'react'
import DataFromDatabase from './DataFromDatabase'
import FileAnalyzeWiz from './FileAnalyzeWiz'

function ReturnPolicy() {
  return (
    <> <FileAnalyzeWiz type="return_policy" />    
    </>
  )
}

export default ReturnPolicy