import React from 'react'
import { Table } from 'react-bootstrap'
import { breakInProcudureTabHeaders, breakInProcudureTabKeys, compatableTabHeaders, compatableTabKeys, detailedTabHeaders, detailedTabKeys, installationTabHeaders, installationTabKeys, productDescriptionTabHeaders, productDescriptionTabKeys, returnPolicyTabHeaders, returnPolicyTabKeys, warrantyTabHeaders, warrantyTabKeys } from '../data/Tabs'
import {decode} from 'html-entities';

function DataTable({type, data}) {

   
    let headers =  []
    let keyheaders =  []

    if(type === 'detailed-product-info'){
        headers = detailedTabHeaders;
        keyheaders = detailedTabKeys;
    }else if(type === 'compatable-with'){
        headers = compatableTabHeaders;
        keyheaders = compatableTabKeys;
    }else if(type === '10-years-warranty'){
        headers = warrantyTabHeaders;
        keyheaders = warrantyTabKeys;
    }else if(type === 'installation-video'){
        headers = installationTabHeaders;
        keyheaders = installationTabKeys;
    }else if(type === 'detailed-product-information'){
        headers = detailedTabHeaders;
        keyheaders = detailedTabKeys;
    }else if(type === 'product-description'){
        headers = productDescriptionTabHeaders;
        keyheaders = productDescriptionTabKeys;
    }else if(type === 'break_in_procedure'){
        headers = breakInProcudureTabHeaders;
        keyheaders = breakInProcudureTabKeys;
    }else if(type === 'return_policy'){
        headers = returnPolicyTabHeaders;
        keyheaders = returnPolicyTabKeys;
    }

   

  return (
    <>
        <Table striped bordered responsive hover size="sm" className="customTablee">
            <thead>
                <tr>
                    {headers.map((item,index)=>{
                        return(
                            <th key={item}>{item}</th>
                        )
                    })}                           
                    
                </tr>
            </thead>
            <tbody>                
                    {data && data.map((item,index)=>{
                        return(
                            <tr key={index}>
                                {
                                    keyheaders.map((keyitem)=>{
                                        return(
                                            <td dangerouslySetInnerHTML={{__html: decode(item[keyitem]) }} ></td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })}
            </tbody>
        </Table>
    </>
  )
}

export default DataTable